.container {
  display: grid;
  grid-template-columns: 22.5% 4.5% 41% 16% 16%;
  grid-gap: 0rem;
}
.grid > div {
  background: #ede7f6;
  padding: 0rem;
  border-radius: 0rem;
}
.exclamation-circle {
  width: 62px;
  height: 59px;
  border-radius: 50%;
  background-color: white;
  margin-top: 29px;
}
.exclamation-triangle-icon {
  width: 35px;
  height: 30px;
  color: #ffa200;
  margin: 12px 0px 0px 13px;
}

.certify-title {
  height: 32px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: white;
}
.certify-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 174px;
}
.certify-title-container {
  display: flex;
  flex: 4;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.certify-terms {
  display: flex;
  flex: 6;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  padding-bottom: 10px;
  cursor: pointer;
}
.certify-terms-checkbox-container {
  margin: 0px 16px 0px 0px;
  padding: 0;
}

.certify-terms-checkbox {
  position: relative;
}
.certify-terms-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20%;
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
}
.certify-terms-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}
.certify-terms-checkbox input[type="checkbox"] {
  visibility: hidden;
}
.certify-terms-checkbox input[type="checkbox"]:checked + label {
  border: solid 1px #979797;
  background-color: #00d9cd;
}
.certify-terms-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.container {
  margin: 0 auto;
}

.certify-terms-descriptions-container {
  font-size: 14px;
  line-height: 20px;
  color: white;
}
.certify-terms-descriptions {
  width: 95%;
}

.certify-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.dispute-link-container {
  font-size: 16px;
  font-weight: bold;
}
.dispute-link-container > a {
  color: white;
}

.btn-confirm {
  width: 228px;
  height: 50px;
  border-radius: 5px;
  font-weight: normal;
  font-size: 24px;
  border: none;
}
.btn-confirm-enabled {
  color: white;
  cursor: pointer;
  background-color: #00d9cd;
}
.btn-confirm-disabled {
  color: #bfcddd;
  background-color: #e1e8f3;
}

.para {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1600px) {
  .container {
    display: grid;
    grid-template-columns: 2% 12% 57% 27% 2%;
    grid-gap: 0rem;
  }
  .certify-title {
    font-size: 18px;
  }
  .btn-confirm {
    width: 180px;
    height: 45px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    display: grid;
    grid-template-columns: 1% 12% 63% 23% 1%;
  }
  .certify-title-container {
    justify-content: flex-start;
  }
  .certify-title {
    display: block;
    font-size: 14px;
    margin-left: 4px;
  }
  .certify-terms-descriptions-container {
    font-size: 12px;
    line-height: 10px;
  }
  .btn-confirm {
    width: 100%;
    height: 45px;
    font-size: 20px;
    border: none;
  }
  .certify-container {
    margin: 20px;
  }
}
