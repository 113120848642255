.worker-preview-modal {
  width: 420px !important;
  height: 688px;
  border-radius: 10px;
  overflow: visible !important;
}
.consent-modal-paper {
  border-radius: 30px !important;
  max-width: 528px !important;
  height: 612px;

  background-color: #f7f9fc !important;
}
.processing-modal-paper {
  border-radius: 30px !important;
  max-width: 528px !important;
  height: 612px;

  background-color: #f7f9fc !important;
}