html,
body {
  height: 100%;
  width: 100%;
  font-weight: 300;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

strong{
  font-weight: 700;
}

*, body.fontLoaded {
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

td{
  font-weight: 300;
}

h1{
  font-weight: 300;
}

* {
  box-sizing: border-box;
}

a {
  color: #7364DB;
  text-decoration: underline;
  display: inline-block;
}
svg {
  vertical-align: middle;
}
#app {
  background-color: #fff;
  min-height: 100%;
  min-width: 100%;
}

p,
label {
  line-height: 1.5em;
}

.turn_tooltip {
  color: #383A38 !important;
  background-color: white !important;
  border: 2px solid #31B4B5;
}
.turn_tooltip.place-top:after {
  border-top-color: #31B4B5 !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

.turn_tooltip.place-right:after {
  border-right-color: #31B4B5 !important;
  border-right-style: solid !important;
  border-right-width: 6px !important;
}

.turn_tooltip.place-left:after{
  border-left-color: #31B4B5 !important;
  border-left-style: solid !important;
  border-left-width: 6px !important;
}

.turn_tooltip.place-bottom:after{
  border-bottom-color: #31B4B5 !important;
  border-bottom-style: solid !important;
  border-bottom-width: 6px !important;
}

.m-signature-pad {
font-size: 10px;
width: 100%;
margin-top: 5px;
max-width: 600px;
height: 120px;
border-bottom: 1px solid #e8e8e8;
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
}

.m-signature-pad--body {
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
border: 1px solid #f4f4f4;
}

.m-signature-pad--body
canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.m-signature-pad--footer {
position: absolute;
right: 20px;
bottom: 20px;
height: 40px;
}

.m-signature-pad--footer
.description {
  color: #C3C3C3;
  text-align: center;
  font-size: 1.2em;
  margin-top: 1.8em;
}

.m-signature-pad--footer
.button {
  position: absolute;
  bottom: 0;
}

.m-signature-pad--footer
.button.clear {
  left: 0;
}

.m-signature-pad--footer
.button.save {
  right: 0;
}

.candidate-signature{
border-bottom: 1px solid #31B4B5;
position: relative;
margin-bottom: 15px;
}

.candidate-signature .m-signature-pad{
height: 150px;
}

.candidate-signature .m-signature-pad--body{
border: none;
}

.candidate-signature .m-signature-pad--footer .button.clear{
background: url(${clearSignature});
width: 35px;
height: 35px;
background-size: contain;
background-repeat: no-repeat;
text-indent: -9999px;
right: 0;
left: auto;
cursor: pointer;
outline: none;
}

.custom-scroll .custom-scrollbar{
background: #ddd;
padding: 0 !important;
opacity: 1 !important;
border-radius: 3px;
}

.custom-scroll .inner-handle{
background-color: rgb(49, 180, 181) !important;
height: 100% !important;
}

.custom-scroll .inner-handle{
margin-top: 0 !important;
}

.dVTaF:nth-child(even) {
  background-color: #f8f8f8;
}

.full-height{
  height: 100%;
}

.router-frame{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.emailed-tooltip {
  color: white;
  background-color: #2d2f46;
  font-size: 12px;
  font-weight: normal;
  padding: 8px 10px;
  position: absolute;
  top: -20px!important;
  left: 0!important;
  width: 180px;
}

.mui-menu-item {
  opacity: 1!important;
}

.mui-menu-item:hover .tooltip-menu-item {
  opacity: 1!important;
  visibility: visible!important;
}
.error-dialog-modal {
  width: -webkit-fit-content !important;
  width: fit-content !important;
  height: -webkit-fit-content;
  height: fit-content;
  border-radius: 30px !important;
  overflow: visible !important;
  background-color: #f7f9fc !important;
}
.spinner {
  width: 40px;
  height: 40px;

  position: relative;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #7364DB;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce1--blue, .double-bounce2--blue {
  background-color: #637DFF;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
.worker-preview-modal {
  width: 420px !important;
  height: 688px;
  border-radius: 10px;
  overflow: visible !important;
}
.consent-modal-paper {
  border-radius: 30px !important;
  max-width: 528px !important;
  height: 612px;

  background-color: #f7f9fc !important;
}
.processing-modal-paper {
  border-radius: 30px !important;
  max-width: 528px !important;
  height: 612px;

  background-color: #f7f9fc !important;
}
.grid-title {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 40px auto;
}

.grid-title .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-title .icon-container .icon {
    width: 40px;
    height: 40px;
}

.grid-title .title-container {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    display: flex;
    justify-content: baseline;
    align-items: center;
}

.sk-chase-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sk-chase {
  width: 60px;
  height: 60px;
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
          animation: sk-chase 2.5s infinite linear both;
  margin: 50px;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  -webkit-animation: sk-chase-dot 2.0s infinite ease-in-out both; 
          animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #00D9CD;
  border-radius: 100%;
  -webkit-animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
          animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:nth-child(1) { -webkit-animation-delay: -1.1s; animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2) { -webkit-animation-delay: -1.0s; animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3) { -webkit-animation-delay: -0.9s; animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4) { -webkit-animation-delay: -0.8s; animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5) { -webkit-animation-delay: -0.7s; animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6) { -webkit-animation-delay: -0.6s; animation-delay: -0.6s; }
.sk-chase-dot:nth-child(1):before { -webkit-animation-delay: -1.1s; animation-delay: -1.1s; }
.sk-chase-dot:nth-child(2):before { -webkit-animation-delay: -1.0s; animation-delay: -1.0s; }
.sk-chase-dot:nth-child(3):before { -webkit-animation-delay: -0.9s; animation-delay: -0.9s; }
.sk-chase-dot:nth-child(4):before { -webkit-animation-delay: -0.8s; animation-delay: -0.8s; }
.sk-chase-dot:nth-child(5):before { -webkit-animation-delay: -0.7s; animation-delay: -0.7s; }
.sk-chase-dot:nth-child(6):before { -webkit-animation-delay: -0.6s; animation-delay: -0.6s; }

@-webkit-keyframes sk-chase {
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
}

@keyframes sk-chase {
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
}

@-webkit-keyframes sk-chase-dot {
  80%, 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
}

@-webkit-keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); 
  } 100%, 0% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0); 
  } 
}

@keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4); 
  } 100%, 0% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0); 
  } 
}
.list-item-text {
  padding-left: 0px !important;
  cursor: pointer;
}

.custom_disabled:hover {
  background-color: transparent !important;
}
.envelope-button {
  background-color: transparent !important;
}
.container {
  display: grid;
  grid-template-columns: 22.5% 4.5% 41% 16% 16%;
  grid-gap: 0rem;
}
.grid > div {
  background: #ede7f6;
  padding: 0rem;
  border-radius: 0rem;
}
.exclamation-circle {
  width: 62px;
  height: 59px;
  border-radius: 50%;
  background-color: white;
  margin-top: 29px;
}
.exclamation-triangle-icon {
  width: 35px;
  height: 30px;
  color: #ffa200;
  margin: 12px 0px 0px 13px;
}

.certify-title {
  height: 32px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: white;
}
.certify-container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  height: 174px;
}
.certify-title-container {
  display: flex;
  flex: 4 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.certify-terms {
  display: flex;
  flex: 6 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  padding-bottom: 10px;
  cursor: pointer;
}
.certify-terms-checkbox-container {
  margin: 0px 16px 0px 0px;
  padding: 0;
}

.certify-terms-checkbox {
  position: relative;
}
.certify-terms-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20%;
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
}
.certify-terms-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 5px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  width: 12px;
}
.certify-terms-checkbox input[type="checkbox"] {
  visibility: hidden;
}
.certify-terms-checkbox input[type="checkbox"]:checked + label {
  border: solid 1px #979797;
  background-color: #00d9cd;
}
.certify-terms-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.container {
  margin: 0 auto;
}

.certify-terms-descriptions-container {
  font-size: 14px;
  line-height: 20px;
  color: white;
}
.certify-terms-descriptions {
  width: 95%;
}

.certify-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.dispute-link-container {
  font-size: 16px;
  font-weight: bold;
}
.dispute-link-container > a {
  color: white;
}

.btn-confirm {
  width: 228px;
  height: 50px;
  border-radius: 5px;
  font-weight: normal;
  font-size: 24px;
  border: none;
}
.btn-confirm-enabled {
  color: white;
  cursor: pointer;
  background-color: #00d9cd;
}
.btn-confirm-disabled {
  color: #bfcddd;
  background-color: #e1e8f3;
}

.para {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1600px) {
  .container {
    display: grid;
    grid-template-columns: 2% 12% 57% 27% 2%;
    grid-gap: 0rem;
  }
  .certify-title {
    font-size: 18px;
  }
  .btn-confirm {
    width: 180px;
    height: 45px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    display: grid;
    grid-template-columns: 1% 12% 63% 23% 1%;
  }
  .certify-title-container {
    justify-content: flex-start;
  }
  .certify-title {
    display: block;
    font-size: 14px;
    margin-left: 4px;
  }
  .certify-terms-descriptions-container {
    font-size: 12px;
    line-height: 10px;
  }
  .btn-confirm {
    width: 100%;
    height: 45px;
    font-size: 20px;
    border: none;
  }
  .certify-container {
    margin: 20px;
  }
}

body {
  overflow-y: scroll !important;
  padding-right: 0 !important;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.turn-modal-paper {
  border-radius: 10px !important;
}

.turn-modal-paper-add-candidate {
  border-radius: 30px !important;
  max-width: 756px !important;
  height: 612px;
  background-color: #f7f9fc !important;
}

.turn-modal-paper-add-candidate-thin {
  border-radius: 30px !important;
  max-width: 528px !important;
  height: 612px;
  background-color: #f7f9fc !important;
}

.partner-name-fixed {
  position: absolute;
  top: 0px;
  right: 0px;
  width: auto;
  height: 20px;
  color: white;
  background-color: rgba(45, 48, 71, 0.61);
  z-index: 1;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.tox-silver-sink {
  z-index: 1600 !important;
}

.mce-notification.mce-in {
  display: none;
}

.paginator__per-page div[role="button"],
.header__sort-dropdown div[role="button"],
.status-action div[role="button"] {
  background: transparent !important;
}
.Toastify__toast-container {
  width: auto;
  max-width: 500px;
}

.Toastify__toast {
  padding: 20px;
  border-radius: 8px;
}

.Toastify__toast-body {
  align-items: start;
  padding: 0;
}

.Toastify__close-button > svg {
  height: 20px;
  width: 20px;
}

.jss6.jss10 .jss1 {
  background-color: #7364DB !important;
}

.jss2.jss3.jss14 {
  border: 0;
}
