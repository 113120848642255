.grid-title {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 40px auto;
}

.grid-title .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-title .icon-container .icon {
    width: 40px;
    height: 40px;
}

.grid-title .title-container {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    display: flex;
    justify-content: baseline;
    align-items: center;
}
