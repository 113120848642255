body {
  overflow-y: scroll !important;
  padding-right: 0 !important;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.turn-modal-paper {
  border-radius: 10px !important;
}

.turn-modal-paper-add-candidate {
  border-radius: 30px !important;
  max-width: 756px !important;
  height: 612px;
  background-color: #f7f9fc !important;
}

.turn-modal-paper-add-candidate-thin {
  border-radius: 30px !important;
  max-width: 528px !important;
  height: 612px;
  background-color: #f7f9fc !important;
}

.partner-name-fixed {
  position: absolute;
  top: 0px;
  right: 0px;
  width: auto;
  height: 20px;
  color: white;
  background-color: rgba(45, 48, 71, 0.61);
  z-index: 1;
}

.App-link {
  color: #61dafb;
}

* {
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.tox-silver-sink {
  z-index: 1600 !important;
}

.mce-notification.mce-in {
  display: none;
}

.paginator__per-page div[role="button"],
.header__sort-dropdown div[role="button"],
.status-action div[role="button"] {
  background: transparent !important;
}
.Toastify__toast-container {
  width: auto;
  max-width: 500px;
}

.Toastify__toast {
  padding: 20px;
  border-radius: 8px;
}

.Toastify__toast-body {
  align-items: start;
  padding: 0;
}

.Toastify__close-button > svg {
  height: 20px;
  width: 20px;
}

.jss6.jss10 .jss1 {
  background-color: #7364DB !important;
}

.jss2.jss3.jss14 {
  border: 0;
}