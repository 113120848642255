html,
body {
  height: 100%;
  width: 100%;
  font-weight: 300;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

strong{
  font-weight: 700;
}

*, body.fontLoaded {
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

td{
  font-weight: 300;
}

h1{
  font-weight: 300;
}

* {
  box-sizing: border-box;
}

a {
  color: #7364DB;
  text-decoration: underline;
  display: inline-block;
}
svg {
  vertical-align: middle;
}
#app {
  background-color: #fff;
  min-height: 100%;
  min-width: 100%;
}

p,
label {
  line-height: 1.5em;
}

.turn_tooltip {
  color: #383A38 !important;
  background-color: white !important;
  border: 2px solid #31B4B5;
}
.turn_tooltip.place-top:after {
  border-top-color: #31B4B5 !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

.turn_tooltip.place-right:after {
  border-right-color: #31B4B5 !important;
  border-right-style: solid !important;
  border-right-width: 6px !important;
}

.turn_tooltip.place-left:after{
  border-left-color: #31B4B5 !important;
  border-left-style: solid !important;
  border-left-width: 6px !important;
}

.turn_tooltip.place-bottom:after{
  border-bottom-color: #31B4B5 !important;
  border-bottom-style: solid !important;
  border-bottom-width: 6px !important;
}

.m-signature-pad {
font-size: 10px;
width: 100%;
margin-top: 5px;
max-width: 600px;
height: 120px;
border-bottom: 1px solid #e8e8e8;
-moz-user-select: none;
-webkit-user-select: none;
-ms-user-select: none;
}

.m-signature-pad--body {
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
border: 1px solid #f4f4f4;
}

.m-signature-pad--body
canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}

.m-signature-pad--footer {
position: absolute;
right: 20px;
bottom: 20px;
height: 40px;
}

.m-signature-pad--footer
.description {
  color: #C3C3C3;
  text-align: center;
  font-size: 1.2em;
  margin-top: 1.8em;
}

.m-signature-pad--footer
.button {
  position: absolute;
  bottom: 0;
}

.m-signature-pad--footer
.button.clear {
  left: 0;
}

.m-signature-pad--footer
.button.save {
  right: 0;
}

.candidate-signature{
border-bottom: 1px solid #31B4B5;
position: relative;
margin-bottom: 15px;
}

.candidate-signature .m-signature-pad{
height: 150px;
}

.candidate-signature .m-signature-pad--body{
border: none;
}

.candidate-signature .m-signature-pad--footer .button.clear{
background: url(${clearSignature});
width: 35px;
height: 35px;
background-size: contain;
background-repeat: no-repeat;
text-indent: -9999px;
right: 0;
left: auto;
cursor: pointer;
outline: none;
}

.custom-scroll .custom-scrollbar{
background: #ddd;
padding: 0 !important;
opacity: 1 !important;
border-radius: 3px;
}

.custom-scroll .inner-handle{
background-color: rgb(49, 180, 181) !important;
height: 100% !important;
}

.custom-scroll .inner-handle{
margin-top: 0 !important;
}

.dVTaF:nth-child(even) {
  background-color: #f8f8f8;
}

.full-height{
  height: 100%;
}

.router-frame{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.emailed-tooltip {
  color: white;
  background-color: #2d2f46;
  font-size: 12px;
  font-weight: normal;
  padding: 8px 10px;
  position: absolute;
  top: -20px!important;
  left: 0!important;
  width: 180px;
}

.mui-menu-item {
  opacity: 1!important;
}

.mui-menu-item:hover .tooltip-menu-item {
  opacity: 1!important;
  visibility: visible!important;
}